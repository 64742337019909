<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-16 17:27:15
 * @listEditTime: Do not edit
-->
<template>
  <video :ref="refsName" style="height: 100%; width: 100%"  muted controls>
    <source type="video/mp4" :src="src" />
  </video>
</template>
  <script>
// import videoPlayer from '@videojs-player/vue'

export default {
  props: {
    srcUrl: {
      type: String,
    },
    refsName: {
      type: String,
    },
  },
  components: {
    //   videoPlayer
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {
    srcUrl() {
      if (this.srcUrl) {
        this.$refs[this.refsName].load()
      }
    },
  },
  methods: {},
}
</script>
  <style lang="scss" scoped>
</style>

